body {
  padding: 0;
  font-family: 'Roboto', 'Segoe UI', -apple-system, BlinkMacSystemFont, 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1em;
  color: #333;
  overflow-y: auto;
  overflow-x: hidden;
}
.video-react .video-react-big-play-button {
  font-size: 3em;
  line-height: 1.5em;
  height: 1.5em;
  width: 3em;
  display: block;
  position: absolute;
  /* top: 10px;
  left: 10px; */
  padding: 0;
  cursor: pointer;
  opacity: 1;
  border: 0.06666em solid #fff;
  background-color: #2b333f;
  background-color: rgba(43, 51, 63, 0.7);
  border-radius: 0.3em;
  transition: all 0.4s;
  top: 50%;
  left: 50%;
  margin-top: -0.75em;
  margin-left: -1.5em;
}
